import * as React from "react"

// styles
const pageStyles = {
  minHeight: '100vh',
  boxSizing: 'border-box',
  backgroundColor: "black",
  color: "#232129",
  padding: "5%",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}
const headingAccentStyles = {
  color: "#663399",
}
const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
  listStyleType: "none",
}
const listItemStyles = {
  marginBottom: 12,
  fontWeight: "300",
  letterSpacing: 1,
}
const linkStyles = {
  color: "#8954A8",
}

// data
const links = [
  {
    text: "Documentation",
    url: "https://www.gatsbyjs.com/docs/",
  },
  {
    text: "Tutorials",
    url: "https://www.gatsbyjs.com/tutorial/",
  },
  {
    text: "Guides",
    url: "https://www.gatsbyjs.com/tutorial/",
  },
  {
    text: "API Reference",
    url: "https://www.gatsbyjs.com/docs/api-reference/",
  },
  {
    text: "Plugin Library",
    url: "https://www.gatsbyjs.com/plugins",
  },
  {
    text: "Cheat Sheet",
    url: "https://www.gatsbyjs.com/docs/cheat-sheet/",
  },
]

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Ta' Spiru Barber Shop</title>
      <img
        alt="Gatsby G Logo"
        style={{width:'25%',minWidth:"200px",margin:'auto',display:"block"}}
        src={require("../images/taspirulogo.png")}
      />
      <p style={{textAlign:'center',color:'white',margin:50}}>
        Website coming soon
      </p>

      <a style={{
          borderWidth: "0pt",
          borderRadius: "8px",
          marginRight: "auto",
          marginLeft: "auto",
          textDecoration: "none",

          cursor: "pointer",
          backgroundColor: "#d2ae6d", 
          color: "black", 
          display: "flex", 
          flexDirection: "row", 
          justifyContent: "center", 
          fontSize: "1.5rem", 

          width: "100%",
          maxWidth: "350px",
          lineHeight: "4rem",
        }}
        href="http://taspiru.bookmy.shop/"
        >
        Book an Appointment
      </a>

      <div style={{textAlign:"center",marginTop:70}}>
        <a style={{display: "inline-block", overflow: "hidden", height: "83px",marginBottom:20}} href='https://play.google.com/store/apps/details?id=com.taspiru.taspiru&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img style={{height: "119px",marginTop:"-17px"}} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
        
        <a href="https://apps.apple.com/mt/app/ta-spiru/id1520960467?itsct=apps_box&amp;itscg=30200" style={{display: "inline-block", overflow: "hidden", borderRadius: "13px", width: "250px", height: "83px",marginBottom:20,}}><img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-US?size=250x83&amp;releaseDate=1602633600&h=9e755b0e304cf48ab3acf447acd844ec" alt="Download on the App Store" style={{borderRadius: "13px", width: "250px", height: "83px"}}/></a>
      </div>
      
      <footer style={{position:'absolute',bottom:0}}>
        <p style={{color:"white",fontSize:"12px"}}>Google Play and the Google Play logo are trademarks of Google LLC.</p>
      </footer>
    </main>
  )
}

export default IndexPage
